/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@tailwind components;
@tailwind utilities;

body {
    margin: 0;
}

.p-confirm-dialog-reject {
    margin-right: 15px;
    padding:10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(235, 235, 235);
}

.p-confirm-dialog-accept{
    color:red;
    padding:10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(255, 236, 236);
}

/* Tables */

p-paginator .p-highlight {
    background-color: rgb(247, 247, 247);
}

/* Add-Edit-Dialogs */

.invalid > span > input, .invalid > div, .invalid {
    border: 1px solid red;
    border-radius: 6px;
}

p-calendar.invalid, p-dropdown.invalid {
    border: none;
}

.text-input > input {
    width: 100%;
    padding: 8px;
}

textarea {
    width: 100%;
}

p-dropdown > div {
    width: 100%;
    border: 1px solid rgb(229, 231, 235);
    margin-bottom: 10px;
}

p-dropdown > div > span.p-placeholder {
    color: rgb(156, 163, 174);
}

p-dropdown > div > span.p-inputtext {
    height: 42px;
    padding-top: 9px;
    padding-bottom: 9px;
}

li.p-dropdown-item {
    height: 46px;
}

p-calendar > span {
    width: 100%;
}

p-calendar > span > input {
    border: 1px solid rgb(229, 231, 235);
    padding: 8px;
}

.p-fileupload-basic > span {
    background: rgb(55, 65, 81);
    border-radius: 1.5rem;
    font-size: 13px;
}

.p-fileupload-basic > span > span {
    font-weight: 400;
}

.p-fileupload-basic > .p-icon-wrapper > svg {
    height: 12px!important;
}

.p-stepper-number {
    color: black;
    background-color: rgb(238, 238, 238);
}

.p-stepper-separator {
    background-color: black;
}

p-stepper .p-button-label{
    font-weight: 400;
} 

.p-datepicker-month {
    margin-right: 10px;
}

.p-datepicker-buttonbar {
    padding-left: 15px;
    padding-right: 15px;
}

.p-autocomplete-input {
    width: 100%;
    height: 42px;
    padding: 8px;
}

.p-autocomplete {
    width: 100%;
    border: 1px solid rgb(229, 231, 235);
    border-radius: 6px;
}

@media print {
    body * {
        visibility: hidden;
    }

    #printableContent {
        position: absolute;
        left: 60px;
        top: -500px;
    }

    #printableContent, #printableContent * {
        visibility: visible;
    }
}